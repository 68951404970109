import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ActiveElements (v1.0.0): cookiestatement.js
 * --------------------------------------------------------------------------
 */

const CookieStatement = (() => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'cookiestatement'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'ae.cookiestatement'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]


  const Default = {}

  const DefaultType = {}

  const Selector = {
    COOKIE_STATEMENT : '[data-plugin="cookiestatement"]', // maybe we should use another selector?
    ACCEPT_BUTTON: '.accept-cookie'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`
  }


  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class CookieStatement {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)

      const days = 365

      if (!this._hasCookie('cookie-statement')) {
        $(this._element).fadeIn('fast')

        $(Selector.ACCEPT_BUTTON, this._element).click(() => {
          this._setCookie('cookie-statement', 'true', days)
          $(this._element).fadeOut('fast')
        })
      }

    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _getCookie(cookieName) {
      const name = `${cookieName}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');

      let c = '';
      let i = 0;

      for (i = 0; i < ca.length; i += 1) {
        c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }

    _hasCookie(cookieName) {
      return Boolean(this._getCookie(cookieName));
    }

    _setCookie(cookieName, cookieValue, expireDays) {
      const oneDayInMillisecond = 86400000;
      const day = new Date();

      day.setTime(day.getTime() + expireDays * oneDayInMillisecond);

      const expires = `expires=${day.toUTCString()}`;
      document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data = $element.data(DATA_KEY)

        if (!data) {
          data = new CookieStatement(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.COOKIE_STATEMENT).each(function () {
      const $cookieStatement = $(this)
      CookieStatement._jQueryInterface.call($cookieStatement, $cookieStatement.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = CookieStatement._jQueryInterface
  $.fn[NAME].Constructor = CookieStatement
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return CookieStatement._jQueryInterface
  }

  return CookieStatement

})(jQuery)

export default CookieStatement
