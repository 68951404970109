import $ from 'jquery'
import Carousel from '../../bootstrap/js/src/carousel'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ActiveElements (v1.0.0): advancedcarousel.js
 * --------------------------------------------------------------------------
 */
const AdvancedCarousel = (() => {

  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */
  const NAME                = 'advancedcarousel'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'ae.advancedcarousel'
  const EVENT_KEY           = `.${DATA_KEY}`
  const PARENT_DATA_KEY     = 'bs.carousel'
  const PARENT_EVENT_KEY    = `.${PARENT_DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    lazySrcAttribute : 'data-lazy-src'
  }

  const DefaultType = {
    lazySrcAttribute : 'string'
  }

  const Selector = {
    ACTIVE_ITEM     : '.active.carousel-item',
    ITEM            : '.carousel-item',
    DATA_RIDE       : '[data-ride="advanced-carousel"]',
    DATA_LAZY_SRC   : Default.lazySrcAttribute
  }

  const Event = {
    LOAD_DATA_API  : `load${EVENT_KEY}${DATA_API_KEY}`,
    SLIDE          : `slide${PARENT_EVENT_KEY}`,
    SLID           : `slid${PARENT_EVENT_KEY}`
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */
  class AdvancedCarousel extends Carousel {

    constructor(element, config) {
      super(element, config);

      // no slide marked as active? make first active
      if ($(this._element).find(Selector.ACTIVE_ITEM)[0] === undefined) {
        const first = $(this._element).find(Selector.ITEM)[0]
        $(first).addClass('active')

        this._lazyLoadImages(first)
      }
    }
    // getters
    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)

      config = super._getConfig(config)

      return config
    }

    _lazyLoadImages(slide) {
      const dataLazySrc = Selector.DATA_LAZY_SRC

      // find img with lazy src attr
      $(slide).find(`img:not([${dataLazySrc}=""])`).each((i, img) => {
        const src = $(img).attr(Selector.DATA_LAZY_SRC)

        $(img).attr('src', src)
        $(img).attr(Selector.DATA_LAZY_SRC, '')
      })
    }

    _addEventListeners() {
      super._addEventListeners()
      $(this._element).on(Event.SLIDE, (event) => this._lazyLoadImages(event.relatedTarget))
    }

    // static
    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config  = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new AdvancedCarousel(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.DATA_RIDE).each(function () {
      const $advancedCarousel = $(this)
      AdvancedCarousel._jQueryInterface.call($advancedCarousel, $advancedCarousel.data())
    })
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */
  $.fn[NAME]             = AdvancedCarousel._jQueryInterface
  $.fn[NAME].Constructor = AdvancedCarousel
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return AdvancedCarousel._jQueryInterface
  }

  return AdvancedCarousel

})(jQuery)

export default AdvancedCarousel
