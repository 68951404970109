import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ActiveElements (v1.0.0): contactform.js
 * --------------------------------------------------------------------------
 */

const ContactForm = (() => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'contactform'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'ae.contactform'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    type      : 'POST',
    dataType  : 'json',
    url       : 'mailer.php'
  }

  const DefaultType = {
    type      : 'string',
    dataType  : 'string',
    url       : 'string'
  }

  const Selector = {
    CONTACTFORM : '[data-form="contact"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`,
    SUBMIT_DATA_API : `submit${EVENT_KEY}${DATA_API_KEY}`
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class ContactForm {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)

      $(this._element).submit(Event.SUBMIT_DATA_API, (event) => {
        event.preventDefault()
        this._submitForm()

        return false
      });
    }

    // getters
    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _submitForm() {
      const form = this._element
      const formData = new FormData(form)

      $(form).addClass('loading');

      $.ajax({
        type: this._config.type,
        url: this._config.url,
        data: formData,
        dataType: this._config.dataType,
        processData: false,
        contentType: false,
        success: (data) => {
          if (data.status) {
            $(form).hide('fast')
            $('.form-success').removeClass('d-none')

          } else {
            $(form).find('.form-messages').html('')
            $.each(data.messages, (field, message) => {
              $(form).find(`[name="${field}"]`).addClass('is-invalid')
              $(form).find('.form-messages').append(`${message}<br />`)
            })

            $.each(data.require, (field, message) => {
              $(form).find(`[name="${field}"]`).addClass('is-invalid')
              $(form).find('.form-messages').append(`${message}<br />`)
            })
          }
        }
      }).done(() => {
        $(form).removeClass('loading')
      })
    }

    // static
    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new ContactForm(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.CONTACTFORM).each(function () {
      const $contactform = $(this)
      ContactForm._jQueryInterface.call($contactform, $contactform.data())
    })
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */
  $.fn[NAME]             = ContactForm._jQueryInterface
  $.fn[NAME].Constructor = ContactForm
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return ContactForm._jQueryInterface
  }

  return ContactForm

})(jQuery)

export default ContactForm
