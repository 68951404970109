import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ActiveElements (v1.0.0): countup.js
 * --------------------------------------------------------------------------
 */

const CountUp = (() => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'countup'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'ae.countup'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {
    from      : 1,
    to        : 100,
    duration  : 1500,
    decimals  : 0,
    prefix    : '',
    suffix    : '',
    seperator : ',',
    decimal   : '.',
    easing    : true,
    grouping  : true
  }

  const DefaultType = {
    from      : 'number',
    to        : 'number',
    duration  : 'number',
    decimals  : 'number',
    prefix    : 'string',
    suffix    : 'string',
    seperator : 'string',
    decimal   : 'string',
    easing    : 'boolean',
    grouping  : 'boolean'
  }


  const Selector = {
    COUNTUP : '[data-plugin="countup"]' // maybe we should use another selector?
  }

  const Event = {
    SCROLL        : `scroll${EVENT_KEY}`,
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`
  }

  /*
  const ClassName = {
  }
  */

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class CountUp {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)
      this.lastTime = 0

      // on startup we set the element to our "from" value
      this._setValue(this._config.from)

      this._initialize()

      $(window).on(Event.SCROLL, (event) => this._process(event))

      this._process()
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public

    start() {
      if (!this.running) {
        this.running = true
        this.animation = this._requestAnimationFrame(this._count)
      }
    }

    pause() {
      if (!this.paused) {
        this.paused = true
        this._cancelAnimationFrame(this.animation)
      }
    }

    resume() {
      if (this.paused) {
        this.paused = false
        delete this.startTime
        this.duration = this.remaining
        this.startVal = this.frameVal
      }
    }

    reset() {
      this.paused = false
      delete this.startTime
      this._initialize()
      window.cancelAnimationFrame(this.animation)
      this._setValue(this.startVal)
    }

    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _process() {
      if (!this._inView()) {
        return;
      }

      this.start()
    }

    _initialize() {
      const ten = 10
      this.startVal = this._config.from
      this.endVal   = this._config.to

      this.decimals = Math.max(0, this._config.decimals || 0)
      this.dec      = Math.pow(ten, this.decimals)

      this.duration  = this._config.duration // * 1000 || 2000
      this.countDown = this.startVal > this.endVal
      this.frameVal  = this.startVal

      this.running = false
    }

    _count(timestamp) {
      if (!this.startTime) {
        this.startTime = timestamp
      }

      this.timestamp = timestamp
      const progress = timestamp - this.startTime
      this.remaining = this.duration - progress;

      if (this._config.easing) {
        if (this.countDown) {
          this.frameVal = this.startVal - this._easeOutExpo(progress, 0, this.startVal - this.endVal, this.duration);
        } else {
          this.frameVal = this._easeOutExpo(progress, this.startVal, this.endVal - this.startVal, this.duration);
        }
      } else if (this.countDown) {
        this.frameVal = this.startVal - (this.startVal - this.endVal) * (progress / this.duration);
      } else {
        this.frameVal = this.startVal + (this.endVal - this.startVal) * (progress / this.duration);
      }

      if (this.countDown) {
        this.frameVal = this.frameVal < this.endVal ? this.endVal : this.frameVal;
      } else {
        this.frameVal = this.frameVal > this.endVal ? this.endVal : this.frameVal;
      }

      this.frameVal = Math.round(this.frameVal * this.dec) / this.dec;

      // format and print value
      this._setValue(this.frameVal);

      // whether to continue
      if (progress < this.duration) {
        this.animation = this._requestAnimationFrame(this._count);
      }
    }

    _inView() {
      const rect = this._element.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    _formatNumber(num) {
      const neg = num < 0
      const thousand = 3
      let i
      let len

      num = Math.abs(num).toFixed(this._config.decimals)
      num = String(num)

      const x = num.split('.')
      let x1 = x[0]
      const x2 = x.length > 1 ? this._config.decimal + x[1] : ''

      if (this._config.grouping) {
        let x3 = ''
        for (i = 0, len = x1.length; i < len; ++i) {
          if (i !== 0 && i % thousand === 0) {
            x3 = this._config.separator + x3
          }
          x3 = x1[len - i - 1] + x3
        }
        x1 = x3
      }

      return (neg ? '-' : '') + this._config.prefix + x1 + x2 + this._config.suffix
    }

    // Robert Penner's easeOutExpo
    _easeOutExpo(t, b, c, d) {
      const num2 = 2
      const numN10 = -10
      const num1024 = 1024
      const num1023 = 1023
      return c * (-Math.pow(num2, numN10 * t / d) + 1) * num1024 / num1023 + b;
    }

    _getValue() {
      let value = 0;

      if (this.tagName === 'INPUT') {
        value = this._element.value
      } else if (this.tagName === 'text' || this.tagName === 'tspan') {
        value = this._element.textContent
      } else {
        value = this._element.innerHTML
      }

      return parseFloat(value);
    }

    _setValue(value) {
      const result = this._formatNumber(value)

      if (this.tagName === 'INPUT') {
        this._element.value = result
      } else if (this.tagName === 'text' || this.tagName === 'tspan') {
        this._element.textContent = result
      } else {
        this._element.innerHTML = result
      }
    }

    _requestAnimationFrame(callback) {
      const currTime = new Date().getTime()
      const num16 = 16
      const timeToCall = Math.max(0, num16 - (currTime - this.lastTime))
      const id = window.setTimeout(() => {
        callback.call(this, currTime + timeToCall)
      }, timeToCall)
      this.lastTime = currTime + timeToCall
      return id
    }

    _cancelAnimationFrame(id) {
      clearTimeout(id)
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new CountUp(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.COUNTUP).each(function () {
      const $countUp = $(this)
      CountUp._jQueryInterface.call($countUp, $countUp.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = CountUp._jQueryInterface
  $.fn[NAME].Constructor = CountUp
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return CountUp._jQueryInterface
  }

  return CountUp

})(jQuery)

export default CountUp
